import Axios from 'config/axios'
import * as EmailValidator from 'email-validator'

export default {
  async validateEmail(email) {
    return await new Promise(async (resolve, reject) => {
      try {
        const valid = EmailValidator.validate(email)

        if (valid) {
          const { data } = await Axios.get(`/usuario/validar-email/${email}`)
          return !data.valido ? 'Este e-mail já está sendo utilizado.' : null
        }

        return resolve('E-mail informado está em um formato inválido!')
      } catch (e) {
        reject(e)
      }
    })
  }
}
