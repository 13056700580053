import React from 'react'
import styled from 'styled-components'
import Logo from 'assets/logo_name.svg'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import applyRegisterLayout from 'components/templates/RegisterLayout'

import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'

const Header = styled.div`
  margin: 2vh 0;
`

const Body = styled.div`
  margin: 2vh 0;

  .MuiFormLabel-root.Mui-focused,
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #185c92;
  }
`

const Footer = styled.div`
  margin: 2vh 0;

  div:first-child {
    margin-bottom: 10px;
  }

  .change-password > label {
    cursor: pointer;
    text-decoration: underline;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 75%;

    button {
      width: 100%;
    }
  }
`

const LogoImg = styled.img`
  max-width: 300px;
`

const Login = () => {
  return (
    <div className="col-lg-6 offset-lg-3 col-md-8 col-sm-8 col-xs-12">
      <div className="container">
        <Header className="col-12 text-center">
          <LogoImg src={Logo} alt="Dr. Book" />
        </Header>
        <Body>
          <Item className="col-12 tex-center">
            <TextField
              variant="outlined"
              id="standard-name"
              label="E-mail"
              margin="normal"
              type="email"
            />
          </Item>
          <Item className="col-12 tex-center">
            <TextField
              variant="outlined"
              id="standard-name"
              label="Senha"
              margin="normal"
              type="password"
            />
          </Item>
          <Item className="col-12">
            <div>
              <FormControlLabel
                control={<Checkbox value="checkedB" color="primary" />}
                label="Manter-me conectado"
              />
            </div>
          </Item>
        </Body>
        <Footer>
          <Item className="col-12 text-center">
            <div>
              <Button variant="contained" color="primary">
                Entrar
              </Button>
            </div>
          </Item>
          <Item className="col-12 change-password">
            <label>Esqueci minha senha...</label>
          </Item>
        </Footer>
      </div>
    </div>
  )
}

export default applyRegisterLayout(Login)
