import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Logo from 'assets/logo_name.svg'

const LogoImage = styled.img`
  height: 25px;
`

const PsNavbar = ({ onClickLogin = () => {} }) => {
  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="#">
          <LogoImage src={Logo} />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#conteudoNavbarSuportado"
          aria-controls="conteudoNavbarSuportado"
          aria-expanded="false"
          aria-label="Alterna navegação"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="conteudoNavbarSuportado">
          <ul className="navbar-nav navbar-left">
            <li className="nav-item">
              <a className="nav-link" href="#inicio">
                Início
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#planos">
                Planos
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#aplicativo">
                Sobre
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contato">
                Contato
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={onClickLogin}
                style={{ cursor: 'pointer' }}
              >
                Login
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default PsNavbar
