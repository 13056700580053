import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'

const IconsDiv = styled.div`
  display: flex;
  flex-direction: row;

  div {
    width: 50px;
    height: 50px;
    background-color: #185c92;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-right: 5px;

    svg {
      height: 25px;
      width: 25px !important;
      color: white;
    }
  }
`

const Title = styled.h2`
  font-weight: 500;
  border-bottom: 1px solid #333;
`

const Endereco = styled.div`
  margin: 15px 0;

  p {
    margin-bottom: 0;
  }
`

const EmailLink = styled.a`
  margin-left: 5px;
  font-weight: bold;
`

const ContactIcons = () => (
  <IconsDiv>
    <div>
      <FontAwesomeIcon icon={faFacebookF} />
    </div>
    <div>
      <FontAwesomeIcon icon={faInstagram} />
    </div>
    <div>
      <FontAwesomeIcon icon={faEnvelope} />
    </div>
  </IconsDiv>
)

const PsContactInfo = () => {
  return (
    <div className="row">
      <div className="col-12">
        <Title>Contato:</Title>
        <p>
          Em caso de dúvidas, sugestões ou reclamações entre em contato através do
          formulário ou pelo e-mail
          <EmailLink href="mailto:contato@drbook.com.br" target="_blank">
            contato@drbook.com.br
          </EmailLink>
          .
        </p>
        <ContactIcons />
        <Endereco>
          <p>Estrada Recanto, Num. 1000</p>
          <p>Restinga Seca / RS - 97200-000 </p>
        </Endereco>
      </div>
    </div>
  )
}

export default PsContactInfo
