import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Logo from 'assets/logo_name.svg'
import classNames from 'classnames'

const LogoImage = styled.img`
  height: 25px;
  cursor: pointer;
`

const Navbar = ({ color, logoAction }) => {
  const navClassnames = classNames({
    'navbar fixed-top navbar-expand-lg': true,
    'navbar-light': true,
    'bg-light': true,
    [color]: true
  })

  console.log(navClassnames)

  return (
    <nav className={navClassnames}>
      <div className="container">
        <a className="navbar-brand" onClick={logoAction}>
          <LogoImage src={Logo} />
        </a>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  // Cor da navbar
  color: PropTypes.oneOf(['bg-light', 'bg-dark', 'bg-custom']),
  // Ação da logo
  logoAction: PropTypes.func
}

Navbar.defaultProps = {
  color: 'bg-light',
  logoAction: () => {}
}

export default Navbar
