import React, { useState } from 'react'
import { faUserMd } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'


/* Atoms */
import UserType from 'components/atoms/UserType'
import TopicTitle from 'components/atoms/TopicTitle'
/* Molecules */
import MultiStepBase from 'components/molecules/MultiStepBase'
/* Organisms */
import FormGenerator from 'components/organisms/FormGenerator'
/* Templates */
import applyRegisterLayout from 'components/templates/RegisterLayout'
/* Data Fields */
import { ACCESS_DATA_FIELDS } from './steps/accessDataFields'
import { CONTACT_FIELDS } from './steps/contactFields'

const Row = styled.div`
  margin-bottom: 30px;
`

const steps = ['Dados de acesso', 'Dados para contato', 'Cadastro concluido']

const DataConfirmation = ({ accessData, contact }) => (
  <>
    <Row className="row">
      <div className="form-group col-md-8 offset-md-2 col-sm-12">
        

      </div>
    </Row>
  </>
)

const RegisterProfessional = props => {
  const [activeStep, setActiveStep] = useState(0)
  /* Access Data */
  const [accessData, setAccessData] = useState({})
  const [accessDataValidForm, setAccessDataValidForm] = useState(false)
  /* Contact */
  const [contact, setContact] = useState({})
  const [contactValidForm, setContactValidForm] = useState(false)

  return (
    <div>
      <UserType icon={faUserMd} title="Médico" />
      <TopicTitle title="Hello World" />
      <MultiStepBase
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        disableNextButton={
          (activeStep === 0 && !accessDataValidForm) ||
          (activeStep === 1 && !contactValidForm) 
        }
        cancelAction={() => props.history.push('/')}
      >
        {/* Primeiro "STEP" -> Cadastro de dados básicos */}
        {
          activeStep === 0 && (
            <FormGenerator
              fields={ACCESS_DATA_FIELDS}
              data={accessData}
              setData={setAccessData}
              setValidForm={setAccessDataValidForm}
            />
          )
        }
        {/* Segundo "STEP" -> Cadastro de contato */}
        {
          activeStep === 1 && (
            <FormGenerator
              fields={CONTACT_FIELDS}
              data={contact}
              setData={setContact}
              setValidForm={setContactValidForm}
            />
          )
        }
        {
          activeStep === 2 && (
            <DataConfirmation 
              accessData={accessData}
              contact={contact}
            />
          )
        }
      </MultiStepBase>
    </div>
  )
}

export default applyRegisterLayout(RegisterProfessional)
