import React from 'react'
import styled from 'styled-components'
import Navbar from 'components/molecules/Navbar'
import Footer from 'components/atoms/Footer'

const Container = styled.div`
  min-height: 100vh;
`

const Content = styled.div`
  min-height: calc(100vh - 54px);
  padding-top: 56px;

  > div {
    padding: 30px 0 !important;
  }
`

const RegisterLayout = (Component) => {
  return props => (
    <Container>
      <Navbar color="bg-custom" logoAction={() => props.history.push('/')} />
      <Content>
        <Component {...props} />
      </Content>
      <Footer />
    </Container>
  )
}

export default RegisterLayout
