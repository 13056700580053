import React, { useState } from 'react'
// Atoms
import Footer from 'components/atoms/Footer'
// Molecules
import PsNavbar from 'components/molecules/cPresentationScreen/PsNavbar'
import PsMedicalImage from 'components/molecules/cPresentationScreen/PsMedicalImage'
import PsApplication from 'components/molecules/cPresentationScreen/PsApplication'
// Organisms
import PsPlans from 'components/organisms/PsPlans'
import PsContact from 'components/organisms/PsContact'

const PresentationPage = (props) => {
  const [visibleSignInModal, setVisibleSignInModal] = useState(false)

  const signUp = () => {
    window.location.href = 'http://drbook.com.br/dashboard/login';
  }

  const doLogin = () => {
    window.location.href = 'http://drbook.com.br/dashboard/login';
  }

  return (
    <div>
      {/* Cabeçalho */}
      <PsNavbar onClickLogin={() => doLogin()} />

      <section id="inicio">
        <PsMedicalImage />
      </section>
      <section id="planos">
        <PsPlans signUpAction={signUp} />
      </section>
      <section id="aplicativo">
        <PsApplication />
      </section>
      <section id="contato">
        <PsContact />
      </section>

      {/* Rodapé */}
      <Footer />
    </div>
  )
}

export default PresentationPage
