import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Card = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  color: #185c92;
  text-align: center;
`

const Title = styled.h3`
  font-weight: bold;
  text-align: center;
  margin: 10px 0 20px 0;
  font-size: 1.5rem;
`

const SubTitle = styled.h5`
  text-align: center;
  font-size: 1rem;
`

const Button = styled.button`
  margin-top: 25px;
  background-color: #185c92 !important;
  border-color: #134973 !important;
  border-radius: 25px;
  width: 75%;

  &:hover,
  &:focus,
  &:active {
    background-color: #134973 !important;
    border-color: #0f3a5a !important;
  }
`

const PsCard = ({ title, icon: Icon, description, callback }) => {
  return (
    <Card className="col-md-4 col-sm-12">
      <FontAwesomeIcon icon={Icon} size="4x" />
      <Title>{title}</Title>
      <SubTitle>{description}</SubTitle>
      <Button className="btn btn-primary" onClick={callback}>
        Assine já
      </Button>
    </Card>
  )
}

export default PsCard
