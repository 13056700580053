import { all, takeEvery, take } from 'redux-saga/effects'

import { AuthenticationType } from '../ducks/authentication'
import { signIn } from './sagaAuthentication'

const authenticationLinks = [takeEvery(AuthenticationType.SIGN_IN_REQUEST, signIn)]

export default function* rootSaga() {
  yield all([...authenticationLinks])
}
