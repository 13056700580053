import React from 'react'
import styled from 'styled-components'
// Molecules
import PsContactForm from 'components/molecules/cPresentationScreen/PsContactForm'
import PsContactInfo from 'components/molecules/cPresentationScreen/PsContactInfo'

const Container = styled.div`
  background-color: #eee;
`

const Item = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`

const PsContact = () => (
  <Container>
    <div className="container">
      <div className="row">
        <Item className="col-md-8 col-sm-12">
          <PsContactForm />
        </Item>
        <Item className="col-md-4 col-sm-12">
          <PsContactInfo />
        </Item>
      </div>
    </div>
  </Container>
)

export default PsContact
