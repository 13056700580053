import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #185c92;
`

const Title = styled.h3`
  font-weight: bold;
  text-align: center;
  margin: 10px 0 20px 0;
  font-size: 1.5rem;
`

const UserType = ({ icon: Icon, title }) => {
  return (
    <Content>
      <FontAwesomeIcon icon={Icon} size="4x" />
      <Title>{title}</Title>
    </Content>
  )
}

UserType.propTypes = {
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired
}

export default UserType
