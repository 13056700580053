import UserProvider from 'providers/UserProvider'

export const CONTACT_FIELDS = [
  {
    id: 'telefone',
    label: 'Telefone',
    type: 'tel',
    required: true,
    minLength: 9,
    maxLength: 11
  },
  {
    id: 'email',
    label: 'E-mail para contato',
    type: 'mail',
    required: true,
    minLength: 5,
    maxLength: 50,
    validation: async email => await UserProvider.validateEmail(email)
  },
  {
    id: 'whatsapp',
    label: 'WhatsApp',
    type: 'tel',
    required: true,
    minLength: 9,
    maxLength: 11
  }
]
