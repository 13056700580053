import { createReducer, createActions } from 'reduxsauce'

/* Types & Actions Creators */
const { Types, Creators } = createActions({
  signInRequest: ['email', 'senha'],
  signInSuccess: ['token', 'user'],
  setAuthenticationError: ['authenticationError']
})

export const AuthenticationType = Types
export default Creators

/* Initial State */
export const INITIAL_STATE = {
  token: null,
  user: {},
  authenticationError: null
}

/* Métodos */
const signInSuccess = (state = INITIAL_STATE, { token, user }) => ({
  ...state,
  token,
  user
})

const setAuthenticationError = (state = INITIAL_STATE, { authenticationError }) => ({
  ...state,
  authenticationError
})

/* Reducers */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SET_AUTHENTICATION_ERROR]: setAuthenticationError
})
