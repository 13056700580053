import React from 'react'
import styled from 'styled-components'

const Container = styled.div``

const ImageBackground = styled.div`
  background-image: url(${require('./assets/medical-background.jpg')});
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 900px) {
    height: calc(100vh - 56px);
  }
`

const TextBackground = styled.div`
  color: white;
  font-weight: bold;
  position: absolute;
  top: calc(25% + 56px);
  width: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 20px;
  text-align: center;

  h1 {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 2rem;
  }

  @media screen and (max-width: 900px) {
    top: 50%;
    width: 80%;
    left: 50%;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`

const PsMedicalImage = () => {
  return (
    <Container>
      <ImageBackground />
      <TextBackground>
        <h1>Busque pelo melhor atendimento</h1>
        <h6>Hospitais, clínicas e médicos em um clique</h6>
      </TextBackground>
    </Container>
  )
}

export default PsMedicalImage
