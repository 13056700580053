import React from 'react'
import PropTypes from 'prop-types'
// Atoms
import Steps from 'components/atoms/Steps'
import BottomNavButtons from 'components/atoms/BottomNavButtons'

const MultiStepBase = ({
  children,
  activeStep,
  setActiveStep,
  cancelAction,
  concludeAction,
  steps,
  disableNextButton,
  cancelText,
  concludeText,
  nextText,
  previousText
}) => {
  const nextStepAction = () => {
    if (activeStep < steps.length - 1) {
      if (!disableNextButton) {
        return setActiveStep(activeStep + 1)
      }
      return false
    }
    return concludeAction()
  }

  const previousStepAction = () => {
    if (activeStep > 0) {
      return setActiveStep(activeStep - 1)
    }
    return cancelAction()
  }

  return (
    <div className="container">
      <Steps activeStep={activeStep} steps={steps} />

      {/* Início do conteúdo do formulário */}
      {children}
      {/* Fim do conteúdo do formulário */}

      <BottomNavButtons
        activeStep={activeStep}
        lastStep={steps.length - 1}
        nextStepAction={() => nextStepAction()}
        previousStepAction={() => previousStepAction()}
        disableNextButton={disableNextButton}
        cancelText={cancelText}
        concludeText={concludeText}
        nextText={nextText}
        previousText={previousText}
      />
    </div>
  )
}

MultiStepBase.propTypes = {
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  cancelAction: PropTypes.func,
  concludeAction: PropTypes.func,
  steps: PropTypes.array.isRequired,
  disableNextButton: PropTypes.bool,
  cancelText: PropTypes.string,
  concludeText: PropTypes.string,
  nextText: PropTypes.string,
  previousText: PropTypes.string
}

MultiStepBase.defaultProps = {
  cancelAction: () => {},
  concludeAction: () => {},
  disableNextButton: false,
  cancelText: 'Cancelar',
  concludeText: 'Concluir',
  nextText: 'Próximo',
  previousText: 'Anterior'
}

export default MultiStepBase
