import React from 'react'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
// Icons
import DashboardIcon from '@material-ui/icons/Dashboard'
import PersonIcon from '@material-ui/icons/Person'
import EventNoteIcon from '@material-ui/icons/EventNote'
import TimelineIcon from '@material-ui/icons/Timeline'
import BuildIcon from '@material-ui/icons/Build'

// End Icons
import { useStyles, Toolbar, Logo, UserIdentificationContainer } from './styles'
import Img from 'assets/logo_name.svg'
import LogoImg from 'assets/logo.svg'
import UserIdentification from 'components/molecules/UserIdentification'
import SidebarItem from 'components/atoms/SidebarItem'

const Sidebar = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const handleDrawer = () => {
    setOpen(!open)
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
    >
      <Toolbar>
        {open && <Logo src={Img} />}
        <IconButton onClick={handleDrawer}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        <ListItem>
          <UserIdentificationContainer>
            <UserIdentification
              avatar={LogoImg}
              text="HUSM - Hospital Universitário de Santa Maria"
              textHidden={!open}
            />
          </UserIdentificationContainer>
        </ListItem>
      </List>
      <Divider />
      <List>
        <SidebarItem Icon={DashboardIcon} text="Dashboard" />
        <SidebarItem Icon={PersonIcon} text="Profissionais" />
        <SidebarItem Icon={EventNoteIcon} text="Agendas" />
        <SidebarItem Icon={TimelineIcon} text="Relatórios" />
        <SidebarItem Icon={BuildIcon} text="Configurações" />
      </List>
    </Drawer>
  )
}

export default Sidebar
