import React, { createRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import LogoBranca from 'assets/logo_name_branca.svg'
import AppImage from './assets/aplicativo.png'

const Container = styled.div`
  .blue-background {
    background-color: #185c92;
    color: white;
  }

  .row {
    padding: 50px 0;
  }
`

const Logo = styled.img`
  max-height: 50px;
  position: relative;
  right: 18px;
`

const ImagemApp = styled.img`
  position: absolute;
  /* left: 50%; */
  right: 0;
`

const FirstRow = () => (
  <div className="col-sm-12 col-md-6">
    <Logo src={LogoBranca} alt="Logo" />
    <p style={{ marginTop: '15px' }}>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Através do aplicativo DrBook
      você pode localizar médicos, clínicas e hospitais na redondeza. Além disso,
      você tem a possibilidade de agendar consultas e manter um controle sobre as
      mesmas na palma de sua mão, em seu Smartphone.
    </p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caso você não saiba como chegar
      em seu destino, ainda há a possíbilidade de traçar uma rota e exportar para seu
      Waze, Google Maps, Mapas ou o que desejar.
    </p>
  </div>
)

const SecondRow = () => (
  <div className="row">
    <div className="col-md-6 col-sm-12">
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Você como contratante terá a
        possíbilidade de ampliar seu negócio alcançando mais clientes e aumentando
        seu rendimento.
      </p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Além disso, você terá acesso
        a diversos relatórios, estatísticas, avalicação de usuários, dentre diversos
        outros benefícios.
      </p>
    </div>
  </div>
)
const PsApplication = () => {
  const container = createRef()
  const [height, setHeight] = useState(null)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    const { current } = container

    setHeight(current.offsetHeight)
    setWidth(current.offsetWidth)
  })

  return (
    <Container ref={container}>
      <div className="blue-background">
        <div className="container">
          <div className="row blue-background">
            {height && width > 600 ? (
              <ImagemApp
                src={AppImage}
                alt="Imagem Aplicativo"
                style={{
                  maxHeight: `${height * 1.05 - 100}px`,
                  marginTop: `${height * -0.05 + 50}px`,
                  maxWidth: `${width / 2}px`
                }}
              />
            ) : null}
            <FirstRow />
          </div>
        </div>
      </div>
      <div className="container">
        <SecondRow />
      </div>
    </Container>
  )
}

export default PsApplication
