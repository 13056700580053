import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Input from 'components/atoms/Input'
import * as EmailValidator from 'email-validator'

const Row = styled.div`
  margin-bottom: 30px;
`

const FormGenerator = ({ fields, data, setData, setValidForm }) => {
  const [errorsFields, setErrorsFields] = useState([])

  useEffect(() => {
    // Seta formulário como válidos e não possuir nenhum item inválido
    setValidForm(!fields.filter(({ id }) => !isValidItem(id)).length)
  }, [data])

  const getInfoField = (id) => fields.find((field) => field.id === id)

  const isValidItem = (id) => {
    const item = getInfoField(id)
    const value = data[id]

    return !(
      (!value && item.required) ||
      value.length < item.minLength ||
      value.length > item.maxLength ||
      (item.type === 'mail' && !EmailValidator.validate(value))
    )
  }

  const setItemError = (id, value = null) => {
    const item = getInfoField(id)
    value = value || data[id]

    if (!value && item.required) {
      return setErrorsFields({
        ...errorsFields,
        [id]: `O preenchimento deste campo é obrigatório.`
      })
    }

    if (value.length < item.minLength || value.length > item.maxLength) {
      return setErrorsFields({
        ...errorsFields,
        [id]: `O campo deve conter entre ${item.minLength} e ${item.maxLength} caracteres.`
      })
    }

    if (item.type === 'mail' && !EmailValidator.validate(value)) {
      return setErrorsFields({
        ...errorsFields,
        [id]: 'O e-mail informado está em um formato inválido.'
      })
    }

    return setErrorsFields({ ...errorsFields, [id]: null })
  }

  const setDataItem = (id, value) => {
    setData({ ...data, [id]: value })
    setItemError(id, value)
  }

  const onFocusOutItem = (id) => setItemError(id)

  return (
    <div>
      {fields.map((item) => (
        <Row className="row" key={item.id}>
          <div className="form-group col-md-8 offset-md-2 col-sm-12">
            <Input
              id={item.id}
              type={item.type}
              label={item.label}
              value={data[item.id]}
              error={!!errorsFields[item.id]}
              errorMessage={errorsFields[item.id]}
              required={item.required}
              onChange={setDataItem}
              onFocusOut={onFocusOutItem}
            />
          </div>
        </Row>
      ))}
    </div>
  )
}

FormGenerator.propTypes = {
  fields: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func
}

FormGenerator.defaultProps = {
  fields: [],
  setData: () => {},
  setValidForm: () => {}
}

export default FormGenerator
