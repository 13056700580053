import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

const drawerWidth = 200

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 70px;
`

export const Logo = styled.img`
  widht: 20px;
  height: 20px;
  margin: 0 auto;
`

export const UserIdentificationContainer = styled.div`
  height: 100px;
  width: 100%;
`

export const useStyles = makeStyles((theme) => ({
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  }
}))
