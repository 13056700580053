import React from 'react'
import styled from 'styled-components'

const DefaultInput = styled.input`
  border-radius: 15px !important;
`

const DefaultTextarea = styled.textarea`
  border-radius: 15px !important;
`

const SubmitButton = styled.button`
  background-color: #185c92 !important;
  border-color: #134973 !important;
  border-radius: 25px;
  padding: 5px 15px;
  float: right;

  &:hover,
  &:focus,
  &:active {
    background-color: #134973 !important;
    border-color: #0f3a5a !important;
  }
`

const PsContactForm = () => {
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <h5>Envie uma mensagem</h5>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6 col-sm-12">
          <DefaultInput className="form-control" placeholder="Seu nome" />
        </div>
        <div className="form-group col-md-6 col-sm-12">
          <DefaultInput className="form-control" placeholder="Seu e-mail" />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <DefaultInput className="form-control" placeholder="Assunto" />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <DefaultTextarea
            className="form-control"
            placeholder="Mensagem"
            rows="4"
            style={{ resize: 'none' }}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <SubmitButton className="btn btn-primary pull-right">Enviar</SubmitButton>
        </div>
      </div>
    </div>
  )
}

export default PsContactForm
