import React from 'react'
import styled from 'styled-components'
import Sidebar from 'components/molecules/Sidebar'

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 50px 0;
`

const Dashboard = (Component) => {
  return (props) => (
    <Container>
      <Sidebar />
      <Content>
        <Component />
      </Content>
    </Container>
  )
}

export default Dashboard
