import UserProvider from 'providers/UserProvider'

export const ACCESS_DATA_FIELDS = [
  {
    id: 'nome',
    label: 'Nome Completo',
    type: 'text',
    required: true,
    minLength: 5,
    maxLength: 50
  },
  {
    id: 'numero_conselho',
    label: 'Número do Conselho',
    type: 'number',
    required: true,
    minLength: 5,
    maxLength: 10
  },
  {
    id: 'email',
    label: 'E-mail',
    type: 'mail',
    required: true,
    minLength: 10,
    maxLength: 50,
    validation: async (email) => await UserProvider.validateEmail(email)
  },
  {
    id: 'senha',
    label: 'Senha',
    type: 'password',
    required: true,
    minLength: 5,
    maxLength: 50
  }
]
