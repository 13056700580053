import React from 'react'
import styled from 'styled-components'

const FooterContainer = styled.div`
  background-color: #ddd;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Footer = () => {
  return <FooterContainer>© Copyright Dr.Book. All Rights Reserved</FooterContainer>
}

export default Footer
