import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Line = styled.div`
  width: 100%;
`

const Title = styled.span``

const TopicTitle = ({ title }) => {
  return (
    <Line>
      <hr />
      <Title>{ title }</Title>
    </Line>
  );
}

TopicTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default TopicTitle;
