import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

const Footer = styled.div`
  margin: 30px 0 15px 0;
  display: flex;
  justify-content: space-between;

  > button {
    height: 56px;
    padding: 10px 25px;

    .MuiSvgIcon-root {
      margin-right: 8px;
    }
  }
`

const BottomNavButtons = ({
  activeStep,
  lastStep,
  nextStepAction,
  previousStepAction,
  disableNextButton,
  cancelText,
  concludeText,
  nextText,
  previousText
}) => (
  <div className="row">
    <Footer className="form-group col-md-8 offset-md-2 col-sm-12">
      <Button
        variant="contained"
        size="small"
        color="default"
        onClick={previousStepAction}
      >
        {activeStep === 0 ? cancelText : previousText}
      </Button>
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={nextStepAction}
        disabled={disableNextButton}
      >
        {activeStep === lastStep ? concludeText : nextText}
      </Button>
    </Footer>
  </div>
)

BottomNavButtons.propTypes = {
  lastStep: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
  nextStepAction: PropTypes.func.isRequired,
  previousStepAction: PropTypes.func.isRequired,
  disableNextButton: PropTypes.bool,
  cancelText: PropTypes.string,
  concludeText: PropTypes.string,
  nextText: PropTypes.string,
  previousText: PropTypes.string
}

BottomNavButtons.defaultProps = {
  disableNextButton: false,
  cancelText: 'Cancelar',
  concludeText: 'Concluir',
  nextText: 'Próximo',
  previousText: 'Anterior'
}

export default BottomNavButtons
