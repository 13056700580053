import React from 'react'
import ReactDOM from 'react-dom'
import Routes from './routes/routes'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'
import * as serviceWorker from './serviceWorker'
import './include/bootstrap'
import './index.scss'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
