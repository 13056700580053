import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

const Input = ({
  id,
  className,
  label,
  value,
  type,
  required,
  onChange,
  onFocusOut,
  error,
  errorMessage
}) => {
  const onChangeEvent = (event) => {
    const { target } = event

    if (target) {
      const { id, value } = target
      return onChange(id, value)
    }
  }

  const onFocusOutEvent = (event) => {
    const { target } = event

    if (target) {
      const { id } = target
      return onFocusOut(id, value)
    }
  }

  return (
    <TextField
      id={id}
      className={`form-control ${className}`}
      label={label}
      type={type}
      required={required}
      error={error}
      helperText={errorMessage}
      value={value}
      onChange={(e) => onChangeEvent(e)}
      onBlur={(e) => onFocusOutEvent(e)}
      variant="outlined"
    />
  )
}

/* Propriedades do componente */
Input.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'number', 'mail', 'telnum']),
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onFocusOut: PropTypes.func
}

/* Valores default das propriedades */
Input.defaultProps = {
  className: '',
  value: '',
  label: '',
  type: 'text',
  required: false,
  error: false,
  errorMessage: '',
  onChange: () => {},
  onFocusOut: () => {}
}

export default Input
