import React from 'react'
import styled from 'styled-components'
import { 
  faUserMd,
  faClinicMedical,
  faHospitalAlt
} from '@fortawesome/free-solid-svg-icons'
// Molcules
import PsCard from 'components/molecules/cPresentationScreen/PsCard'

const Container = styled.div`
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
`

const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  color: #185c92;
`

const SubTitle = styled.h5`
  font-size: 1.5rem;
  color: #185c92;
`

const DOCTOR_DESCRIPTION =
  'Impulsione seu consultório médico e garanta um controle de agenda de atendimentos.'
const HOSPITAL_DESCRIPTION =
  'Além de impulsionar seu hospital, mantenha um controle nos atendimentos de seu hospital através de relatórios.'
const CLINIC_DESCRIPTION =
  'Impulsione, controle seus atendimentos, emitia relatórios, dentre diversas outras funcionalidades.'

const PsPlans = ({ signUpAction = () => {} }) => {
  return (
    <Container className="container">
      <div className="row">
        <div className="col-md-12">
          <SubTitle>Diversos planos, com cobertura total</SubTitle>
          <Title>Selecione o melhor para você!</Title>
        </div>
      </div>

      <div className="row">
        <PsCard
          icon={faUserMd}
          title="Profissionais"
          description={DOCTOR_DESCRIPTION}
          callback={() => signUpAction('/cadastrar/profissional')}
        />
        <PsCard
          icon={faHospitalAlt}
          title="Hospitais"
          description={HOSPITAL_DESCRIPTION}
          callback={() => signUpAction('/cadastrar/hospital')}
        />
        <PsCard
          icon={faClinicMedical}
          title="Clinicas"
          description={CLINIC_DESCRIPTION}
          callback={() => signUpAction('/cadastrar/clinica')}
        />
      </div>
    </Container>
  )
}

export default PsPlans
