import React from 'react'
import { PresentationPage } from '../pages'
import { Switch, Route } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons'
import { ThemeProvider } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import RegisterProfessional from 'pages/RegisterProfessional'
import DashboardPage from 'pages/DashboardPage'
import Login from 'pages/Login'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#185c92',
      dark: '#134973',
      contrastText: '#fff'
    }
  }
})

library.add(faStroopwafel)

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route exact path="/" component={PresentationPage} />
      </Switch>
    </ThemeProvider>
  )
}

export default App
