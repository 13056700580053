import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'

const SidebarItem = ({ Icon, text, selected, clickable, onClick, active }) => {
  return (
    <ListItem
      disabled={!active}
      selected={selected}
      button={clickable}
      action={onClick}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )
}

SidebarItem.propTypes = {
  Icon: PropTypes.any.isRequired,
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  clickable: PropTypes.bool,
  onCLick: PropTypes.func,
  active: PropTypes.bool
}

SidebarItem.defaultProps = {
  selected: false,
  clickable: true,
  onClick: () => {},
  active: true
}

export default SidebarItem
