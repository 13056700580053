import React from 'react'
import Avatar from '../../atoms/Avatar'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
`

const Text = styled.span`
  min-width: 150px;
  font-size: 16px;
  text-align: center;
`

const UserIdentification = ({ avatar, text, textHidden }) => {
  return (
    <Container>
      <Avatar avatar={avatar} />
      {!textHidden && <Text>{text}</Text>}
    </Container>
  )
}

UserIdentification.propTypes = {
  avatar: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textHidden: PropTypes.bool
}

UserIdentification.defaultProps = {
  textHidden: false
}

export default UserIdentification
