import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AvatarMaterial from '@material-ui/core/Avatar'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(5.5),
    height: theme.spacing(5.5),
    color: theme.palette.getContrastText(grey[100]),
    backgroundColor: grey[100]
  }
}))

const Avatar = ({ avatar }) => {
  const classes = useStyles()

  return <AvatarMaterial className={classes.avatar} src={avatar} />
}

Avatar.propTypes = {
  avatar: PropTypes.string.isRequired
}

export default Avatar
